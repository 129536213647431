import { Vue, Options } from 'vue-class-component';
import { mapState } from 'vuex';
import { IonIcon, IonItem, IonLabel, IonList, IonText } from '@ionic/vue';
import { PlantReferenceDTO } from 'client-generator/generated-clients/client';
import { SET_CURRENT_PLANT } from '@/core/store/action-types';

@Options({
    name: 'PlantSelectorPage',
    components: {
        IonIcon,
        IonItem,
        IonLabel,
        IonList,
        IonText,
    },
    computed: {
        ...mapState(['plantList', 'currentPlant']),
    },
})
export default class PlantSelectorPage extends Vue {
    selectedPlant: PlantReferenceDTO | null = null;
    currentPlant!: PlantReferenceDTO | null;
    plantList!: PlantReferenceDTO[];

    mounted() {
        this.selectPlant(this.currentPlant);
    }

    selectPlant(plant: PlantReferenceDTO | null) {
        this.selectedPlant = plant;
    }

    confirmPlant() {
        this.$store.dispatch(SET_CURRENT_PLANT, this.selectedPlant);
        this.$messageService.message(
            this.$t('plantSelector.success', {
                plantName: `${this.selectedPlant?.name}`,
            })
        );
        this.$router.push('/');
    }
}
