<template>
    <ion-page>
        <TheHeader />
        <ion-content>
            <div class="container d-flex flex-column h-100 pb-4">
                <div class="text-left">
                    <h1 class="fs-20">
                        {{ $t("plantSelector.selectPlant") }}
                    </h1>
                    <p class="fs-14">
                        {{ $t("plantSelector.selectPlantLong") }}
                    </p>
                </div>

                <ion-list
                    v-if="selectedPlant && currentPlant"
                    id="plant-list"
                    class="mb-3"
                    mode="ios"
                    style="
                        --ion-item-background: transparent;
                        --background: transparent;
                    "
                >
                    <ion-item
                        @click="selectPlant(plant)"
                        lines="none"
                        class="py-2 border-bottom cursor-pointer"
                        v-for="plant in plantList"
                        :key="plant.plantId"
                        mode="ios"
                        style="--padding-start: 0; --inner-padding-end: 0"
                    >
                        <ion-label>{{ plant.name }}</ion-label>

                        <font-awesome-icon
                            :icon="[
                                'fal',
                                selectedPlant.plantId === plant.plantId
                                    ? 'check-circle'
                                    : 'circle',
                            ]"
                            class="fs-34"
                            :class="
                                selectedPlant.plantId === plant.plantId
                                    ? 'text-primary'
                                    : 'text-borders'
                            "
                            slot="end"
                        />
                    </ion-item>
                </ion-list>
                <div class="mt-auto">
                    <ion-button
                        color="primary"
                        expand="block"
                        @click="confirmPlant()"
                    >
                        {{ $t("plantSelector.btn.confirm") }}
                    </ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>

<script lang="ts" src="./PlantSelector.ts"></script>
